<template lang="pug">
header#header(:class="{'ativo': ativo}")
    .wrapper
        router-link(:to="{name: 'home'}", tag="h1").logo
            |CA2A - A casa da segunda casa
            SvgIcon(data="@svgs/logo_ca2a.svg", original)
        nav
            ul.links
                li.link(@click="toggleMenu()")
                    router-link(:to="{name: 'home'}") Home
                li.link(@click="toggleMenu()")
                    router-link(:to="{name: 'Sobre'}") Sobre nós
                li.link(@click="toggleMenu()")
                    router-link(:to="{name: 'Empreendimentos'}") Investimentos
                li.link(@click="toggleMenu()")
                    router-link(:to="{name: 'ComoFunciona'}") Como funciona
                //- li.link(@click="toggleMenu()")
                //-     a(href="https://show.morrodosanjos.com.br/", target="_blank") Área do associado
                //- li.link(@click="toggleMenu()")
                //-  a(href="#") Contato
                //- li.link(@click="toggleMenu()")
                //-  a(href="#") Imprensa
            RedesEcontatos
        button.openMenu(@click="toggleMenu()")
            i.first
            i.second
</template>

<script>
import RedesEcontatos from '@components/RedesEcontatos/RedesEcontatos';
export default {
  name: 'component-header',
  components: {
    RedesEcontatos
  },
  data() {
    return {
      ativo: false
    }
  },
  methods: {
    toggleMenu() {
      this.ativo = !this.ativo
    }
  }
}
</script>

<style lang="stylus" scoped src="./Header.styl"></style>
