/**
 * Sitemap
 *  Referência: https://github.com/cheap-glitch/vue-cli-plugin-sitemap#readme
 *  Adicionar o seguinte na rota em que precise alterar manualmente os valores do sitemap, com os respectivos valores:
        meta: {
            sitemap: {
                lastmod: new Date(),
                priority: 0.8,
                changefreq: 'daily',
            }
        }
 */

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@views/Home/Home')
  },
  {
    path: '/como-funciona',
    name: 'ComoFunciona',
    component: () => import(/* webpackChunkName: "ComoFunciona" */ '@views/ComoFunciona/ComoFunciona')
  },
  {
    path: '/sobre-nos',
    name: 'Sobre',
    component: () => import(/* webpackChunkName: "SobreNos" */ '@views/SobreNos/SobreNos')
  },
  {
    path: '/investimentos',
    name: 'Empreendimentos',
    component: () => import(/* webpackChunkName: "Empreendimentos" */ '@views/Empreendimentos/Empreendimentos')
  },
  {
    path: '/investimentos/:slug',
    name: 'Empreendimento',
    component: () => import(/* webpackChunkName: "Empreendimento" */ '@views/Empreendimento/Empreendimento'),
    meta: {
      sitemap: {
        slugs: [
          'morro-dos-anjos',
          'tayaya-porto-rico',
          'tayaya-angra-doce',
          'tayaya-view'
        ]
      }
    }
  }
//   {
//     path: '/imagens-em-tempo-real',
//     name: 'BuildersShow',
//     component: () => import(/* webpackChunkName: "BuildersShow" */ '@views/BuildersShow/BuildersShow')
//   }
]

export default routes
