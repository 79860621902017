<template lang="pug">
    Aside(:ativo="contents != null" @close="close()")#asideGaleria
        .controls(v-if="contents.length > 0")
            button.left(@click="changeImage(ativo - 1)")
                FontAwesomeIcon(:icon="icons.faChevronLeft")
            button.right(@click="changeImage(ativo + 1)")
                FontAwesomeIcon(:icon="icons.faChevronRight")
        ul.items
            li(v-for="image, index in contents", :class="{'ativo': ativo == index}").item
                .img
                    img(:src="image").fit
        ul.nav-items
            li(v-for="image, index in contents" @click="changeImage(index)", :class="{'ativo': ativo == index}" :style="style").nav-item
                .img
                    img(:src="image").fit
</template>

<script>
import Aside from '@components/Aside/Aside.vue'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "component-aside-galeria",
    components: {
        Aside
    },
    data() {
        return {
            icons: {
                faChevronLeft, faChevronRight
            },
            style: {
                transform: "translateX(0)"
            }
        }
    },
    computed: {
        contents() {
            return this.$store.state.asideGaleria.contents || []
        },
        ativo: {
            get() {
                return this.$store.state.asideGaleria.index
            },
            set(index) {
                this.$store.commit('setAsideGaleria', {
                    contents: this.contents,
                    index
                })
            }
        }
    },
    methods: {
        close() {
            this.$store.commit('setAsideGaleria', {
                index: 0,
                contents: null
            })
        },
        changeImage(index){
            if(index >= this.contents.length){
                this.ativo = 0
            } else if(index < 0){
                this.ativo = this.contents.length - 1
            } else {
                this.ativo = index
            }

            if (this.ativo <= this.contents.length - 4) {
                let gap = parseFloat(window.getComputedStyle(document.querySelector(".nav-items")).gap)
                let move = (document.querySelector(".nav-item").offsetWidth + gap) * this.ativo 
                this.style.transform = `translateX(-${move}px)`
            } else if (this.ativo == this.contents.length - 1) {
                let gap = parseFloat(window.getComputedStyle(document.querySelector(".nav-items")).gap)
                let move = (document.querySelector(".nav-item").offsetWidth + gap) * (this.ativo - 3) 
                this.style.transform = `translateX(-${move}px)`
            }
        },
    },
}
</script>

<style lang="stylus" scoped src="./AsideGaleria.styl"></style>