<template lang="pug">
section#fiquePorDentro
    form(@submit.prevent="send")
        //- h3 Fique por dentro
        h2 Fique por dentro das novidades!
        p
            |A CA2A possui excelentes investimentos para quem busca as melhores acomodações ou um ótimo retorno financeiro.
            br
            |Preencha suas informações e descubra tudo o que a CA2A pode oferecer para você.
        .group
            .input._33(:class="{ error: form_error.nome }")
                input(id="input_nome", name="input_nome", type="text", placeholder="Nome:", v-model="form.fields.nome")
                span {{ form_error.nome }}
            .input._33(:class="{ error: form_error.email }")
                input(id="input_email", name="input_email", type="email", placeholder="E-mail:", v-model="form.email")
                span {{ form_error.email }}
            .input._33(:class="{ error: form_error.telefone }")
                input(id="input_nome", name="input_nome", type="tel", placeholder="Telefone:", v-model="form.fields.telefone", v-mask="['(##) #####-####', '(##) ####-####']")
                span {{ form_error.telefone }}
        ul.redes
            li.rede
                a(href="https://www.facebook.com/ca2aoficial", target="_blank")
                    FontAwesomeIcon(:icon="icons.faFacebookF")
            li.rede
                a(href="https://www.instagram.com/ca2aoficial/", target="_blank")
                    FontAwesomeIcon(:icon="icons.faInstagram")
            li.rede
                a(href="https://www.linkedin.com/company/ca2a/", target="_blank")
                    FontAwesomeIcon(:icon="icons.faLinkedin")
        button(type="submit", v-html="buttonText", :disabled="disabled").roundedButton
</template>

<script>
import { faFacebookF, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { mask } from 'vue-the-mask'

const FORMULARIO = {
  subject: 'Contato',
  email: '',
  origin: 'Contato | CA2A',
  client: 'CA2A',
  fields: {
    nome: '',
    telefone: ''
  },
  requiredFields: ['nome', 'telefone'],
  sendto: process.env.VUE_APP_SEND_TO,
  sendfrom: process.env.VUE_APP_SEND_FROM
}

export default {
  name: 'component-fique-por-dentro',
  directives: {
    mask
  },
  data() {
    return {
      form: { ...FORMULARIO, fields: { ...FORMULARIO.fields } },
      form_error: {},
      buttonText: 'Enviar',
      disabled: false,
      errors: [],
      icons: {
        faFacebookF,
        faInstagram,
        faLinkedin
      }
    }
  },
  methods: {
    async send() {
      this.buttonText = 'Enviando...'
      this.disabled = true
      this.form_error = {}

      await this.$axios.post('send', this.form)
        .then(response => {
          this.buttonText = 'Enviado com sucesso!'
          this.form = { ...FORMULARIO, fields: { ...FORMULARIO.fields } }

          // eslint-disable-next-line no-undef
          ga('gtm1.send', 'event', 'clique_form1', 'contato_form1', 'enviar1')
        })
        .catch(error => {
          this.form_error = JSON.parse(error.response.data.errors)
          this.buttonText = 'Ocorreu um erro!'
        })
        .finally(() => {
          setTimeout(() => {
            this.buttonText = 'Enviar'
            this.disabled = false
          }, 6000)
        })
    }
  }
}
</script>

<style lang="stylus" scoped src="./FiquePorDentro.styl"></style>
