<template lang="pug">
#redesEcontatos
    ul.redes
        li.rede
            a(href="https://www.instagram.com/ca2aoficial/", target="_blank")
                FontAwesomeIcon(:icon="icons.faInstagram")
        li.rede
            a(href="https://www.facebook.com/ca2aoficial", target="_blank")
                FontAwesomeIcon(:icon="icons.faFacebookSquare")
        li.rede
            a(href="https://www.linkedin.com/company/ca2a/", target="_blank")
                FontAwesomeIcon(:icon="icons.faLinkedin")
    .formasContatos
        .contato
            span E-mail:{{ ' ' }}
            a(href="mailto:contato@ca2a.com.br") contato@ca2a.com.br
        .contato
            span Telefone:{{ ' ' }}
            a(href="tel:+554331458080") 43 3145-8080
        .contato
            span Whatsapp:{{ ' ' }}
            a(href="https://wa.me/5543991959243") 43 99195-9243
</template>

<script>
import { faFacebookSquare, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';

export default {
  name: 'component-redes-econtatos',
  data() {
    return {
      icons: {
        faFacebookSquare,
        faInstagram,
        faLinkedin,
        faYoutube
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="./RedesEcontatos.styl"></style>
