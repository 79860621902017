<template lang="pug">
footer#footer
    .wrapper
        .navegacao
            h1.logo
                |CA2A - A casa da segunda casa
                SvgIcon(data="@svgs/logo_ca2a.svg", original)
            .lists
                ul.links
                    li.head Navegação
                    //- li
                    //-     a(href="#") Conceito
                    //- li
                    //-     a(href="#") Diferenciais
                    //- li
                    //-     a(href="#") Propriedades
                    li
                        router-link(:to="{name: 'ComoFunciona'}") Como funciona
                    //- li
                    //-     a(href="#") Contato
                ul.follow
                    li.head Siga-nos
                    li
                        a(href="https://www.instagram.com/ca2aoficial/", target="_blank") Instagram
                    li
                        a(href="https://www.facebook.com/ca2aoficial", target="_blank") Facebook
                    li
                        a(href="https://www.linkedin.com/company/ca2a/", target="_blank") Linkedin
        .contatos
            ul.redes
                li.rede
                    a(href="https://www.instagram.com/ca2aoficial/", target="_blank")
                        FontAwesomeIcon(:icon="icons.faInstagram")
                li.rede
                    a(href="https://www.facebook.com/ca2aoficial", target="_blank")
                        FontAwesomeIcon(:icon="icons.faFacebookSquare")
                li.rede
                    a(href="https://www.linkedin.com/company/ca2a/", target="_blank")
                        FontAwesomeIcon(:icon="icons.faLinkedin")
            .formasContatos
                .contato
                    span E-mail:{{ ' ' }}
                    a(href="mailto:contato@ca2a.com.br") contato@ca2a.com.br
                .contato
                    span Telefone:{{ ' ' }}
                    a(href="tel:+554331458080") 43 3145-8080
                .contato
                    span Whatsapp:{{ ' ' }}
                    a(href="https://wa.me/5543991959243") 43 99195-9243
        .direitos
            p CA2A © {{year}} Todos os direitos reservados.
            a(href="#") Políticas de privacidade

</template>

<script>
import { faFacebookSquare, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'

export default {
  name: 'component-footer',
  data() {
    return {
      icons: {
        faFacebookSquare,
        faInstagram,
        faLinkedin,
        faYoutube
      }
    }
  },
  computed: {
    year() {
      const ano = new Date()
      return ano.getFullYear()
    }
  }
}
</script>

<style lang="stylus" scoped src="./Footer.styl"></style>
