<template lang="pug">
	main#app
		RedesEcontatos
		Header
		article#page
			router-view
		FiquePorDentro
		Footer
		AsideGaleria(v-if="$store.state.asideGaleria.contents")
</template>

<script>
import Header from '@components/Header/Header'
import RedesEcontatos from '@components/RedesEcontatos/RedesEcontatos'
import FiquePorDentro from '@components/FiquePorDentro/FiquePorDentro'
import Footer from '@components/Footer/Footer'
import AsideGaleria from '@components/AsideGaleria/AsideGaleria.vue'

export default {
	name: 'template-app',
	metaInfo() {
		return {
			// title: 'Base Vuejs',
			meta: [
				{ name: 'description', content: '' },
			],
		}
	},
	components: {
		RedesEcontatos,
		Header,
		FiquePorDentro,
		Footer,
		AsideGaleria,
	},
}
</script>

<style lang="stylus" scoped src="./App.styl"></style>
