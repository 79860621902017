import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		asideGaleria: {
			index: 0,
			contents: null
		},
		asideVideo: null
	},
	mutations: {
		setAsideGaleria(state, payload) {
			Vue.set(state, 'asideGaleria', payload)
		},
		setAsideVideo(state, payload) {
			Vue.set(state, 'asideVideo', payload)
		},
	},
	actions: { },
	modules: { }
})
