<template lang="pug">
    transition(name="component-aside")
        aside.main-aside(v-if="ativo", ref="componentAside", @click.self="close", @keydown.esc="close", tabindex="0")
            .main-content
                button(type="button", title="Fechar", @click="close").fechar
                    FontAwesomeIcon(:icon="icons.faXmark")
                slot
</template>

<script>
import { faXmark } from '@fortawesome/free-solid-svg-icons'

export default {
    name: "component-aside",
    props: {
        ativo: {
            default: false,
            type: Boolean
        },
    },
    data() {
        return {
            icons: {
                faXmark,
            },
        }
    },
    beforeCreate() {
        document.querySelector('html').classList.add('overflow-hidden')
        document.querySelector('body').classList.add('overflow-hidden')
        setTimeout(() => this.$refs.componentAside.focus(), 600)
    },
    beforeDestroy() {
        document.querySelector('html').classList.remove('overflow-hidden')
        document.querySelector('body').classList.remove('overflow-hidden')
    },
    // watch: {
    //     '$props.ativo': function() {
    //         if (this.$props.ativo) {
    //             document.querySelector('html').classList.add('overflow-hidden')
    //             document.querySelector('body').classList.add('overflow-hidden')
    //             setTimeout(() => this.$refs.componentAside.focus(), 600)
    //         } else {
    //             document.querySelector('html').classList.remove('overflow-hidden')
    //             document.querySelector('body').classList.remove('overflow-hidden')
    //         }
    //     },
    // },
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style lang="stylus" scoped src="./Aside.styl"></style>
<style lang="stylus">
    html, body
        &.overflow-hidden
            overflow hidden !important
</style>